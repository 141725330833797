var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"watchers-list-table"},[(_vm.loader && _vm.localWatchers.length === 0)?_c('div',{staticClass:"text-center"},[_c('Loader',{attrs:{"size":'big'}})],1):[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table align-middle table-row-dashed fs-6 gy-5",attrs:{"id":"kt_ecommerce_products_table"}},[_vm._m(0),_c('tbody',{staticClass:"fw-semibold text-gray-600"},_vm._l((_vm.localWatchers),function(watcher,index){return _c('tr',{key:index},[_c('td',[_c('span',[_vm._v(_vm._s(watcher.type))])]),_c('td',[_c('span',[_vm._v(_vm._s(watcher.metric))])]),_c('td',[_c('span',[_vm._v(_vm._s(watcher.value))])]),_c('td',[_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(watcher.created_at)))])]),_c('td',[_c('span',{staticClass:"badge",class:{
                  'badge-primary': watcher.status === 'LOW',
                  'badge-warning': watcher.status === 'MEDIUM',
                  'badge-danger': watcher.status === 'HIGH',
                  'badge-secondary': watcher.status === 'PENDING'
                }},[_vm._v(" "+_vm._s(watcher.status)+" ")])]),_c('td',[_c('span',[_vm._v(_vm._s(watcher.is_active ? 'Sim' : 'Não'))])]),_c('td',{staticClass:"d-flex gap-2"},[_c('span',[_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":{ name: 'watchers.show', params: { id: watcher.id } }}},[_c('i',{staticClass:"fas fa-eye px-0"})])],1),(watcher.is_active)?_c('Button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-danger py-2",attrs:{"type":"button","title":"Desativar"},on:{"click":function($event){return _vm.destroyWatcher(watcher.id)}}},[_c('i',{staticClass:"fa-solid fa-xmark p-0"})]):_vm._e(),(!watcher.is_active)?_c('Button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-success py-2",attrs:{"type":"button","title":"Ativar"},on:{"click":function($event){return _vm.activateWatcher(watcher.id)}}},[_c('i',{staticClass:"fa-solid fa-check p-0"})]):_vm._e()],1)])}),0)])]),_c('div',{staticClass:"text-center mt-4"},[(_vm.isLoadMoreVisible)?_c('Button',{staticClass:"btn-primary",attrs:{"loading":_vm.loader},on:{"click":_vm.loadMore}},[_vm._v(" Carregar mais ")]):_vm._e()],1)]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0"},[_c('th',{staticClass:"min-w-100px"},[_vm._v("Tipo")]),_c('th',{staticClass:"min-w-70px"},[_vm._v("Métrica")]),_c('th',{staticClass:"min-w-70px"},[_vm._v("Regra")]),_c('th',{staticClass:"min-w-70px"},[_vm._v("Data de criação")]),_c('th',{staticClass:"min-w-70px"},[_vm._v("Status")]),_c('th',{staticClass:"min-w-70px"},[_vm._v("Ativo")]),_c('th',{staticClass:"min-w-70px"},[_vm._v("Ações")])])])
}]

export { render, staticRenderFns }