<template>
  <div class="watchers-list-table">
    <div v-if="loader && localWatchers.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else>
      <div class="table-responsive">
        <!--begin::Table-->
        <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
          <!--begin::Table head-->
          <thead>
            <!--begin::Table row-->
            <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
              <th class="min-w-100px">Tipo</th>
              <th class="min-w-70px">Métrica</th>
              <th class="min-w-70px">Regra</th>
              <th class="min-w-70px">Data de criação</th>
              <th class="min-w-70px">Status</th>
              <th class="min-w-70px">Ativo</th>
              <th class="min-w-70px">Ações</th>
            </tr>
            <!--end::Table row-->
          </thead>
          <!--end::Table head-->
          <!--begin::Table body-->
          <tbody class="fw-semibold text-gray-600">
            <!--begin::Table row-->
            <tr v-for="(watcher, index) in localWatchers" :key="index">
              <td>
                <span>{{ watcher.type }}</span>
              </td>
              <td>
                <span>{{ watcher.metric }}</span>
              </td>
              <td>
                <span>{{ watcher.value }}</span>
              </td>
              <td>
                <span>{{ watcher.created_at | formatDate }}</span>
              </td>
              <td>
                <span
                  class="badge"
                  :class="{
                    'badge-primary': watcher.status === 'LOW',
                    'badge-warning': watcher.status === 'MEDIUM',
                    'badge-danger': watcher.status === 'HIGH',
                    'badge-secondary': watcher.status === 'PENDING'
                  }"
                >
                  {{ watcher.status }}
                </span>
              </td>
              <td>
                <span>{{ watcher.is_active ? 'Sim' : 'Não' }}</span>
              </td>
              <td class="d-flex gap-2">
                <span>
                  <router-link
                    :to="{ name: 'watchers.show', params: { id: watcher.id } }"
                    class="btn btn-sm btn-primary"
                  >
                    <i class="fas fa-eye px-0"></i>
                  </router-link>
                </span>
                <Button
                  type="button"
                  class="btn btn-danger py-2"
                  @click="destroyWatcher(watcher.id)"
                  v-if="watcher.is_active"
                  v-b-tooltip.hover title="Desativar"
                >
                  <i class="fa-solid fa-xmark p-0"></i>
                </Button>
                <Button
                  type="button"
                  class="btn btn-success py-2"
                  @click="activateWatcher(watcher.id)"
                  v-if="!watcher.is_active"
                  v-b-tooltip.hover title="Ativar"
                >
                  <i class="fa-solid fa-check p-0"></i>
                </Button>
              </td>
            </tr>
            <!--end::Table row-->
          </tbody>
          <!--end::Table body-->
        </table>
      </div>
      <!--end::Table-->
      <div class="text-center mt-4">
        <Button
          class="btn-primary"
          :loading="loader"
          @click="loadMore"
          v-if="isLoadMoreVisible"
        >
          Carregar mais
        </Button>
      </div>
    </template>
  </div>
</template>

<script>

import NotificationService from '@/modules/notifications/services/notifications-service'
import WatchersService from '@/modules/watchers/services/watchers-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'WatcherListTable',
  props: ['campaignId', 'watchers'],
  components: {
    Button,
    Loader
  },
  data () {
    return {
      localWatchers: [],
      loader: false,
      page: 1,
      isLoadMoreVisible: true
    }
  },
  created () {
    if (this.watchers) {
      this.localWatchers = this.watchers
      this.isLoadMoreVisible = false
    } else {
      this.getWatchers()
    }
  },
  computed: {},
  methods: {
    /**
     * Submit
     */
    async activateWatcher (id) {
      this.overlayLoader = true
      try {
        await WatchersService.activateWatcher(id)
        const notification = {
          notification: {
            type: 'success',
            content: 'Watcher ativado com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.getWatchers()
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.overlayLoader = false
    },
    /**
     * Submit
     */
    async destroyWatcher (id) {
      this.overlayLoader = true
      try {
        await WatchersService.destroyWatcher(id)
        const notification = {
          notification: {
            type: 'success',
            content: 'Watcher desativado com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.getWatchers()
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.overlayLoader = false
    },
    /**
     * Get watchers
     */
    async getWatchers () {
      if (!this.campaignId) return false

      this.loader = true
      this.localWatchers = []
      try {
        const watchers = await WatchersService.getWatchers({
          campaign_id: this.campaignId,
          page: this.page
        })
        this.localWatchers = [...this.localWatchers, ...watchers.data]
        this.isLoadMoreVisible = watchers.meta.current_page !== watchers.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getWatchers()
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localWatchers = []
      this.getWatchers()
    }
  }
}

</script>
