<template>
  <b-modal
    :id="modalId"
    :modal-class="'default-modal'"
    header-class="justify-content-between"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >

    <template #modal-header="{ close }">
      <div class="text-center">
        <h2 class="highlighted-color1">
          <span>{{ campaignGoalId ? 'Editar meta' : 'Criar meta' }}</span>
        </h2>
      </div>
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close()">
          <span class="svg-icon svg-icon-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
            </svg>
          </span>
      </div>
      <!--end::Close-->
    </template>

    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-4">
      <template v-if="loader">
        <div class="text-center">
          <Loader
            :size="'big'"
          />
        </div>
      </template>
      <UpsertCampaignGoalForm
        :campaign="campaign"
        :campaignGoal="campaign_goal"
        @upserted="handleUpsertedGoal"
        v-else
      />
    </div>
  </b-modal>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import CampaignGoalsService from '@/modules/campaigns/services/campaign-goals-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Loader from '@/components/common/Loader/Loader'
import UpsertCampaignGoalForm from '@/components/campaign-goals/UpsertCampaignGoalForm'

export default {
  name: 'UpsertCampaignGoalModal',
  props: ['campaignId', 'campaignGoalId'],
  components: {
    AlertWraper,
    Loader,
    UpsertCampaignGoalForm
  },
  data () {
    return {
      campaign: null,
      campaign_goal: null,
      content: null,
      content_type: 'error',
      loader: false
    }
  },
  computed: {
    modalId () {
      return this.campaignGoalId ? `upsert-campaign-goal-${this.campaignGoalId}` : 'upsert-campaign-goal'
    }
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
      this.campaign = null
      this.campaign_goal = null
      this.content = null
    },
    /**
     * On modal shown
     */
    shown () {
      this.getCampaign()
      if (this.campaignGoalId) this.getCampaignGoal()
    },
    /**
     * Get campaign
     */
    async getCampaign () {
      this.loader = true
      try {
        const campaign = await CampaignsService.getCampaign(this.campaignId)
        this.campaign = campaign
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    },
    /**
     * Get campaign goal
     */
    async getCampaignGoal () {
      this.loader = true
      try {
        // eslint-disable-next-line camelcase
        const campaign_goal = await CampaignGoalsService.getCampaignGoal(this.campaignGoalId)
        // eslint-disable-next-line camelcase
        this.campaign_goal = campaign_goal
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    },
    /**
     * Handle upserted goal
    */
    handleUpsertedGoal (goal) {
      this.$emit('upserted', goal)
      const notification = {
        notification: {
          type: 'success',
          content: 'Meta salva com sucesso'
        }
      }
      NotificationService.createGlobalSuccessNotification(notification)
      this.$bvModal.hide(this.modalId)
    }
  }
}
</script>
