<template>
    <div class="watcher-logs-list-table">
      <div v-if="loader && localGoals.length === 0" class="text-center">
        <Loader
          :size="'big'"
        />
      </div>
      <template v-else>
        <div class="table-responsive">
          <!--begin::Table-->
          <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
            <!--begin::Table head-->
            <thead>
              <!--begin::Table row-->
              <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                <th class="min-w-100px">Metrica</th>
                <th class="min-w-70px">Valor</th>
                <th class="min-w-70px">Status</th>
                <th class="min-w-70px">Data de criação</th>
                <th class="min-w-70px">Ações</th>
              </tr>
              <!--end::Table row-->
            </thead>
            <!--end::Table head-->
            <!--begin::Table body-->
            <tbody class="fw-semibold text-gray-600">
              <!--begin::Table row-->
              <tr v-for="(goal, index) in localGoals" :key="index">
                <td>
                  <span>{{ goal.metric }}</span>
                </td>
                <td>
                  <span v-if="goal.metric == 'CPM'">{{ parseValue(goal)/100 | formatMoney }}</span>
                  <span v-else-if="goal.metric == 'CTR'">{{ parseValue(goal) }}%</span>
                  <span v-else>{{ parseValue(goal) }}</span>
                </td>
                <td>
                  <span>
                    {{ goal.is_active ? 'Ativo' : 'Inativo' }}
                  </span>
                </td>
                <td>
                  <span>{{ goal.created_at }}</span>
                </td>
                <td>
                  <Button
                    class="btn btn-primary"
                    v-b-modal="'upsert-campaign-goal-' + goal.id"
                  >
                    <i class="fa-solid fa-pen-to-square p-0"></i>
                  </Button>
                  <Button
                    class="btn btn-danger ms-3"
                    v-b-modal="'destroy-campaign-goal-modal-' + goal.id"
                  >
                    <i class="fa-solid fa-xmark p-0"></i>
                  </Button>
                  <DestroyCampaignGoalModal
                    :campaign_goal="goal"
                    @destroyed="reset"
                  />
                  <UpsertCampaignGoalModal
                    :campaignId="campaignId"
                    :campaignGoalId="goal.id"
                    @upserted="reset"
                  />
                </td>
              </tr>
              <!--end::Table row-->
            </tbody>
            <!--end::Table body-->
          </table>
        </div>
        <!--end::Table-->
        <div class="text-center mt-4">
          <Button
            class="btn-primary"
            :loading="loader"
            @click="loadMore"
            v-if="isLoadMoreVisible"
          >
            Carregar mais
          </Button>
        </div>
      </template>
    </div>
  </template>

<script>

import CampaignGoalsService from '@/modules/campaigns/services/campaign-goals-service'

import Button from '@/components/common/Button/Button'
import DestroyCampaignGoalModal from '@/components/campaign-goals/DestroyCampaignGoalModal'
import Loader from '@/components/common/Loader/Loader'
import UpsertCampaignGoalModal from '@/components/campaign-goals/UpsertCampaignGoalModal'

export default {
  name: 'CampaignGoalListTable',
  props: ['goals', 'campaignId', 'reload'],
  components: {
    Button,
    DestroyCampaignGoalModal,
    Loader,
    UpsertCampaignGoalModal
  },
  data () {
    return {
      localGoals: [],
      loader: false,
      page: 1,
      isLoadMoreVisible: true
    }
  },
  created () {
    if (this.goals) {
      this.localGoals = this.logs
      this.isLoadMoreVisible = false
    } else {
      this.getGoals()
    }
  },
  watch: {
    /**
     * Watcher for reload prop
    */
    reload (newValue) {
      if (newValue) {
        this.reset()
      }
    }
  },
  methods: {
    /**
     * Get goals
     */
    async getGoals () {
      if (!this.campaignId) return false

      this.loader = true
      try {
        const goals = await CampaignGoalsService.getCampaignGoals({
          campaign_id: this.campaignId,
          page: this.page
        })
        this.localGoals = [...this.localGoals, ...goals.data]
        this.isLoadMoreVisible = goals.meta && (goals.meta.current_page !== goals.meta.last_page)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getGoals()
    },
    /**
     * Parse the expected field given goal
     */
    parseValue (goal) {
      return goal.value
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localGoals = []
      this.getGoals()
    }
  }
}

</script>
