<template>
  <div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-4">
        <form class="default-form" @submit.prevent="">
          <div class="mb-5">
            <!--begin::Label-->
            <label class="form-label fw-semibold">Métrica</label>
            <!--end::Label-->
            <select class="form-select" v-model.trim="form.metric">
              <option value="CLICK">Clique</option>
              <option value="CTR">CTR</option>
              <option value="CPM">CPM</option>
              <option value="IMPRESSION">Impressão</option>
            </select>
          </div>
          <div class="mb-5">
            <!--begin::Label-->
            <label class="form-label fw-semibold">Valor</label>
            <!--end::Label-->
            <!--begin::Input-->
            <Money
              v-if="form.metric === 'CPM'"
              class="form-control mb-3"
              v-model.trim="form.value"
              v-bind="money"
            />
            <Money
              v-else-if="form.metric === 'CTR'"
              class="form-control mb-3"
              v-model.trim="form.value"
              v-bind="percentage"
            />
            <input
              v-else
              type="number"
              class="form-control"
              v-model="form.value"
            />
          </div>
          <div class="mt-3 text-center">
            <Button
              class="btn btn-primary"
              @click="submit"
              :loading="loader"
            >
              Salvar
            </Button>
          </div>
        </form>
    </div>
  </div>
</template>

<script>

import CampaignGoalsService from '@/modules/campaigns/services/campaign-goals-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import { Money } from 'v-money'

export default {
  name: 'UpsertCampaignGoalForm',
  props: ['campaign', 'campaignGoal'],
  components: {
    AlertWraper,
    Button,
    Money
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      form: {
        metric: '',
        value: ''
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      percentage: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        precision: 2,
        masked: false
      }
    }
  },
  created () {
    if (this.campaignGoal) this.setCampaignGoal()
  },
  watch: {
    campaignGoal (newVal) {
      if (newVal) this.setCampaignGoal()
    }
  },
  methods: {
    /**
     * set campaign goal
     */
    setCampaignGoal () {
      this.form.metric = this.campaignGoal.metric
      this.form.value = this.campaignGoal.value
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true
      try {
        this.form.campaign_id = this.campaign.id
        const form = JSON.parse(JSON.stringify(this.form))
        let goal = null

        if (!this.campaignGoal) {
          goal = await CampaignGoalsService.createCampaignGoals(form)
        } else {
          goal = await CampaignGoalsService.updateCampaignGoals(this.campaignGoal.id, form)
        }
        this.$emit('upserted', goal)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }

      this.loader = false
    },
    /**
     * On modal hidden
     */
    hidden () {
      this.content = null
    }
  }
}
</script>
