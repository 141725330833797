<template>
  <b-modal
    :id="`destroy-campaign-goal-modal-${campaign_goal.id}`"
    :modal-class="'default-modal'"
    header-class="justify-content-between"
    :hide-footer="true"
    @hidden="hidden"
  >
  <template #modal-header="{ close }">
      <div class="text-center">
        <h2 class="highlighted-color1">
          <span>Remover meta de campanha</span>
        </h2>
      </div>
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close()">
          <span class="svg-icon svg-icon-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
            </svg>
          </span>
      </div>
      <!--end::Close-->
    </template>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-4">
      <h4 class="text-center mb-10">Tem certeza de que deseja apagar a meta {{ campaign_goal.metric }}?</h4>
      <div class="mt-3 text-center">
        <Button
          class="btn btn-primary"
          @click="submit"
          :loading="loader"
        >
          Apagar
        </Button>
      </div>
    </div>
  </b-modal>
</template>

<script>

import CampaignGoalsService from '@/modules/campaigns/services/campaign-goals-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  name: 'DestroyCampaignGoalModal',
  props: ['campaign_goal'],
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false
    }
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
      this.content = null
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true
      try {
        await CampaignGoalsService.destroyCampaignGoal(this.campaign_goal.id)
        const notification = {
          notification: {
            type: 'success',
            content: 'Meta apagada com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$emit('destroyed', this.campaign_goal)
        this.$bvModal.hide(`destroy-campaign-goal-modal-${this.campaign_goal.id}`)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
